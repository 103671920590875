<template>
  <Dialog
    v-model:visible="modalDistribucion"
    :breakpoints="{'1300px': '100vw'}" :style="{width: '70vw'}"
    :modal="true"
    :closeOnEscape="true"
    :dismissableMask="true"
    @hide="onCloseModal"
  >
    <template #header>
      <div style="color:#1c3faa;">
        <h4>
          <i class="pi pi-bars" style="font-size: 1.5rem;margin-right:10px"></i>
          <strong style="font-size:20px">Distribución de medicamentos</strong>
        </h4>
      </div>
    </template>
    <div class="grid lg:grid-cols-8 xl:grid-cols-8 grid-cols-1 gap-2">
      <div class="col-span-4">
        <span class="font-bold text-xs">Cod. Medicamento: </span><br>
        <p class="text-xs">{{ encabezado.U_PHR_ItemCodSolicitado }} -
          {{ encabezado.ItemNameOriginal ?? encabezado.Dscription }}</p>
      </div>
      <div>
        <div>
          <span class="font-bold text-xs">Conversión Medicamento: </span><br>
          <p class="text-xs">{{ encabezado.U_PHR_Conversion }}</p>
        </div>
      </div>
      <div>
        <div>
          <span class="font-bold text-xs">Cantidad Factura: </span><br>
          <p class="text-xs">{{ encabezado.Quantity }}</p>
        </div>
      </div>
      <div>
        <div>
          <span class="font-bold text-xs">Cantidad Disponible: </span><br>
          <p class="text-xs">{{ encabezado.receivedQuantity - onQuantity }}</p>
        </div>
      </div>
      <div>
        <div>
          <span class="font-bold text-xs">Cantidad Defectuosa: </span><br>
          <p class="text-xs">{{ encabezado.cantidadDefectuosa }}</p>
        </div>
      </div>
    </div>
    <div class="grid lg:grid-cols-6 xl:grid-cols-6 grid-cols-1 gap-2">
      <div class="col-span-3" v-if="encabezado.ItemCode !== encabezado.U_PHR_ItemCodSolicitado">
        <span class="font-bold text-xs">Cod. Medicamento Sustituto: </span><br>
        <p class="text-xs">{{ encabezado.ItemCode }} - {{ encabezado.U_PHR_ItemSustituto ?? encabezado.ItemName }}</p>
      </div>
      <div v-if="encabezado.U_PHR_ItemSustituto">
        <span class="font-bold text-xs">Conversión sustituto: </span><br>
        <p class="text-xs">{{ encabezado.U_PHR_ConversionSustituto }}</p>
      </div>
    </div>
    <div class="pt-8">
      <DataTable
        :value="detalle"
        class="p-datatable-sm text-xs"
        showGridlines
        responsiveLayout="scroll"
        editMode="row"
        v-model:editingRows="editingRows"
        @row-edit-save="onRowEditSave"
        scrollHeight="500px"
        @row-edit-cancel="onRowEditCancel"
      >
        <template #empty>
          No hay datos disponibles para mostrar.
        </template>
        <template #header>
          <div class="flex justify-end align-items-center" v-if="encabezado.receivedQuantity - onQuantity > 0">
            <span class="p-input-icon-left">
              <Button icon="pi pi-plus" label="Agregar Distribución" @click="addDistribucion"
                      class="p-button-outlined p-button-success p-button-sm"/>
            </span>
          </div>
        </template>
        <Column field="" header="Bodega" bodyClass="align-items:center" headerClass="lg:text-xs" headerStyle="width:20%"
                class="my-1 py-1 shadow-xs">
          <template #body="{data}">
            <div class="grid grid-cols-5">
              <div v-if="data.WhsCode" class="flex justify-center col-span-4 items-center my-1 pr-2 pl-2">
                <div>
                  <p>
                    <alert-circleIcon v-if="data.U_PHR_WarehouseCodeOri !== data.WhsCode && data.U_PHR_WarehouseCodeOri"
                                      class="mr-1 w-4 mb-1 text-yellow-400"/>
                    {{ data.WhsCode }} - {{ data.WhsName }}
                  </p>
                </div>
              </div>
              <div class="flex gap-2">
                <Badge value="Original" v-if="!data.distribucionNueva && data.solicitudes.length > 0"
                       class=" bg-green-300 text-green-700 text-xxs h-4 capitalize"></Badge>
                <Badge value="Nueva" v-if="!data.id && data.distribucionNueva && data.WhsCode"
                       class=" bg-red-300 text-red-700 text-xxs h-4 capitalize"></Badge>
              </div>
            </div>
            <div class="flex justify-center">
              <div v-if="data.U_PHR_WarehouseCodeOri !== data.WhsCode && data.U_PHR_WarehouseCodeOri">
                <small class="color-red flex justify-center"><p>Esta distribución se modificó</p></small>
                <small class="color-red"><p>Bodega original: {{ data.U_PHR_WarehouseCodeOri }} - {{
                    data.NombreBodegaOri
                  }}</p></small>
              </div>
              <div v-if="!data.WhsCode" class="grid grid-cols-1 text-center">
                <p>Presione el botón de editar para asignar una bodega.</p>
              </div>
              <Badge value="Nueva" v-if="!data.id && data.distribucionNueva && !data.WhsCode"
                     class=" bg-red-300 text-red-700 text-xxs h-4 capitalize"></Badge>
            </div>
          </template>
          <template #editor="slotProps">
            <Dropdown
              v-model="slotProps.data.WhsCode"
              class="rounded-xs border-gray-300 w-full text-xs text-center p-inputtext-xs"
              :options="bodegas"
              optionLabel="bodega"
              @click="$h.xxsInput"
              panelClass="text-xxs"
              optionValue="WhsCode"
              placeholder="Seleccione una bodega..."
              @change="onChange($event,slotProps)"
              :filter="true"
            />
          </template>
        </Column>
        <Column field="Quantity" header="Cantidad" headerStyle="width:5%" bodyStyle="text-align: center"
                headerClass="lg:text-xs" class="my-1 py-1 shadow-xs">
          <template #body="{data}">
            <p class="text-xs">{{ $h.formatNumber(data.TempQuantity) }}</p>
          </template>
          <template #editor="slotProps">
            <div class="justify-center flex">
              <InputNumber v-model="slotProps.data.TempQuantity" class="p-inputtext-xs" inputId="minmax" showButtons
                           buttonLayout="horizontal"
                           :min="1"
              />
            </div>
          </template>
        </Column>
        <Column field="QuantitySol" header="Cant. Solicitudes" headerStyle="width:10%" bodyStyle="text-align: center"
                headerClass="lg:text-xs" class="my-1 py-1 shadow-xs">
          <template #body="{data}">
            <p class="text-xs">{{ $h.formatNumber(data.QuantitySol) }}</p>
          </template>
          <template #editor="{data}">
            <p class="text-xs">{{ $h.formatNumber(data.QuantitySol) }}</p>
          </template>
        </Column>
        <Column field="QuantitySol" header="Info. Solicitudes" headerStyle="width:25%" bodyStyle="text-align: center"
                headerClass="lg:text-xs" class="my-1 py-1 shadow-xs">
          <template #body="{data}">
            <div class="grid grid-cols-1" v-if="data.distribucionNueva">
              <div class="text-xs">
                <small class="color-red">Esta distribución es nueva, por lo tanto no tiene información de
                  solicitudes</small>
              </div>
            </div>
            <div class="grid grid-cols-1" v-if="data.distribucionDefectuosa">
              <div class="text-xs">
                <small class="color-red">Esta distribución es la cantidad de medicamento defectuosa ingresada y no se
                  podrá modificar.</small>
              </div>
            </div>
            <div v-for="(item, index) in data.solicitudes" v-else :key="index" class="grid grid-cols-2">
              <div class="text-xs">
                <strong>Número Solicitud: </strong><span>{{ item.DocNumSolicitud }}</span>
              </div>
              <div class="text-xs">
                <strong>Cant. Solicitud: </strong><span>{{ $h.formatNumber(item.cantidadSolicitud) }}</span>
              </div>
            </div>
          </template>
          <template #editor="{data}">
            <div class="grid grid-cols-1" v-if="data.distribucionNueva">
              <div class="text-xs">
                <small class="color-red">Esta distribución es nueva, por lo tanto no tiene información de
                  solicitudes</small>
              </div>
            </div>
            <div v-for="(item, index) in data.solicitudes" v-else :key="index" class="grid grid-cols-2">
              <div class="text-xs">
                <strong>Número Solicitud: </strong><span>{{ item.DocNumSolicitud }}</span>
              </div>
              <div class="text-xs">
                <strong>Cant. Solicitud: </strong><span>{{ $h.formatNumber(item.cantidadSolicitud) }}</span>
              </div>
            </div>
          </template>
        </Column>
        <Column header="Ubicación" style="min-width:7rem" headerStyle="width:15%" bodyStyle="text-align:center">
          <template #body="{data}">
            <div class="text-xs">
              <span>{{ data.nombreUbicacion }}</span>
            </div>
          </template>
          <template #editor="{data}">
            <div class="text-xs">
              <span>{{ data.nombreUbicacion }}</span>
            </div>
          </template>
        </Column>
        <Column header="Acciones" :rowEditor="true" style="min-width:7rem" headerStyle="width:5%"
                bodyStyle="text-align:center">
          <template #body="slotProps">
            <div v-if="!slotProps.data.distribucionDefectuosa">
              <Button @click="initEditRows(slotProps.data)" icon="pi pi-pencil"
                      class="p-button-rounded p-button-text p-button-plain"/>
              <Button @click="remove(slotProps)" icon="pi pi-minus-circle"
                      class="p-button-rounded p-button-text p-button-plain" v-tooltip="'Remover distribución'"/>
            </div>
          </template>
        </Column>
      </DataTable>
    </div>
    <div :class="`${detalle.every((x) => x.id) ? 'justify-between' : 'justify-center'} flex mt-2`">
      <Button icon="pi pi-print" label="Imprimir" v-if="detalle.every((x) => x.id)" class="p-button-outlined p-button-secondary p-button-sm" @click="printPdf"/>
      <Button icon="pi pi-check-square" label="Confirmar" class="p-button-outlined p-button-sm" @click="onSubmit"/>
    </div>
  </Dialog>
</template>

<script>
import { computed, ref } from 'vue'
import Swal from 'sweetalert2'
import RecepcionPedidosService from '@/apps/pharmasan/compras/logistica/services/rcp-pedidos.service'

export default {
  name: 'modalDistribucion',
  emits: ['refrescarDistribucion', 'refrescarCantidad', 'validateBod', 'distriBodega'],
  setup (props, { emit }) {
    const modalDistribucion = ref(false)
    const editingRows = ref([])
    const _RecepcionPedidosService = ref(new RecepcionPedidosService())
    const detalle = ref([])
    const distribucionBodega = ref(false)
    const detalleTemp = ref([])
    const infoDoc = ref({})
    const encabezado = ref({})
    const onQuantity = computed(() => {
      return detalle.value.reduce((total, current) => {
        return total + parseInt(current.TempQuantity)
      }, 0)
    })
    const bodegas = ref([])

    const onSubmit = () => {
      if ((encabezado.value.receivedQuantity) - onQuantity.value !== 0) {
        Swal.fire({
          icon: 'info',
          title: 'Información',
          text: 'Hay inconsistencias en las cantidades, por favor revisar',
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true
        })
        return
      }
      if (editingRows.value.length > 0) {
        Swal.fire({
          icon: 'info',
          title: 'Información',
          text: 'Faltan distribuciones por asignar, por favor verifique e inténtelo nuevamente',
          showConfirmButton: false,
          timer: 3500,
          timerProgressBar: true
        })
        return
      }
      const filterBod = detalle.value.filter((x) => !x.WhsCode)
      if (filterBod.length) {
        Swal.fire({
          icon: 'info',
          title: 'Información',
          text: 'Por favor seleccione una bodega e inténtelo nuevamente',
          showConfirmButton: false,
          timer: 3500,
          timerProgressBar: true
        })
        return
      }
      Swal.fire({
        icon: 'question',
        title: '¿Esta seguro? ',
        html: '¿Desea guardar esta distribución para este medicamento?',
        showConfirmButton: true,
        showCancelButton: true,
        focusCancel: true,
        confirmButtonText: 'Si, confirmar',
        cancelButtonText: 'No, cancelar'
      }).then(({ value }) => {
        if (value) {
          const arrayT = detalle.value.map((x) => {
            return {
              id: x.id ?? null,
              idDetalleRecepcion: encabezado.value.idDetalle ?? null,
              CodBodega: x.WhsCode,
              cantOriSolicitudes: x.QuantitySol,
              CodBodegaOri: x.U_PHR_WarehouseCodeOri ?? null,
              NombreBodega: x.WhsName,
              cantidad: x.Quantity,
              AbsEntry: x.AbsEntry ?? null,
              deletedAt: null
            }
          })
          if (distribucionBodega.value) {
            const payload = {
              cargaUtil: arrayT,
              datos: detalle.value,
              linea: encabezado.value
            }
            emit('distriBodega', payload)
            modalDistribucion.value = false
          } else {
            const payload = {
              datos: arrayT
            }
            _RecepcionPedidosService.value.postDistribucion(payload).then((resp) => {
              if (resp.status === 200) {
                Swal.fire({
                  icon: 'success',
                  title: 'Éxito',
                  text: 'Distribución asignada correctamente.',
                  showConfirmButton: true,
                  confirmButtonText: 'Ok'
                }).then((resp) => {
                  if (resp.isConfirmed) {
                    const data = {
                      cantidad: detalle.value.reduce((acc, val) => acc + val.Quantity, 0),
                      line: encabezado.value
                    }
                    emit('refrescarCantidad', data)
                    modalDistribucion.value = false
                  }
                })
              }
            })
          }
        }
      })
    }
    const printPdf = () => {
      if ((encabezado.value.receivedQuantity) - onQuantity.value !== 0) {
        Swal.fire({
          icon: 'info',
          title: 'Información',
          text: 'Hay inconsistencias en las cantidades, por favor revisar',
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true
        })
        return
      }
      if (editingRows.value.length > 0) {
        Swal.fire({
          icon: 'info',
          title: 'Información',
          text: 'Faltan distribuciones por asignar, por favor verifique e inténtelo nuevamente',
          showConfirmButton: false,
          timer: 3500,
          timerProgressBar: true
        })
        return
      }
      const filterBod = detalle.value.filter((x) => !x.WhsCode)
      if (filterBod.length) {
        Swal.fire({
          icon: 'info',
          title: 'Información',
          text: 'Por favor seleccione una bodega e inténtelo nuevamente',
          showConfirmButton: false,
          timer: 3500,
          timerProgressBar: true
        })
        return
      }
      const datos = detalle.value.map((x) => {
        return {
          nit: '900249425-1',
          direccion: 'Calle 32 # 30-34',
          telefono: '6960254-6960255',
          DocNum: infoDoc.value.DocEntry,
          CardName: `${infoDoc.value.CardCode} - ${infoDoc.value.CardName}`,
          DocDate: infoDoc.value.DocDate,
          ItemCode: `${encabezado.value.ItemCode} - ${encabezado.value.ItemName}`,
          U_PHR_RegSanitario: encabezado.value.U_PHR_RegSanitario,
          nomBodega: `${x.WhsCode} - ${x.WhsName}`,
          Quantity: x.Quantity
        }
      })
      _RecepcionPedidosService.value.imprimirPdfDistribucion(infoDoc.value.DocEntry, encabezado.value.LineNum, datos)
    }

    const onRowEditCancel = ({ data, index }) => {
      const findBod = bodegas.value.find((l) => l.WhsCode === data.BodegaSelec)
      if (findBod.SolicitudLinea !== undefined && findBod.SolicitudLinea.length && findBod.CantidadSolicitud) {
        detalle.value[index].solicitudes = findBod.SolicitudLinea
        detalle.value[index].QuantitySol = findBod.CantidadSolicitud
        detalle.value[index].distribucionNueva = !data.solicitudes.length > 0
      } else {
        detalle.value[index].solicitudes = undefined
        detalle.value[index].QuantitySol = 0
        detalle.value[index].distribucionNueva = true
      }
      detalle.value[index].TempQuantity = data.Quantity
      detalle.value[index].WhsCode = data.BodegaSelec
      detalle.value[index].WhsName = data.NomBodegaSelec
      if (data.BodegaSelec === process.env.VUE_APP_WHSCODE_RECEPCION_PEDIDOS) {
        detalle.value[index].nombreUbicacion = process.env.VUE_APP_NOMBRE_UBICACION_PENDIENTESOV
        detalle.value[index].AbsEntry = process.env.VUE_APP_ABS_ENTRY_UBICACION_PENDIENTES
      } else {
        detalle.value[index].nombreUbicacion = 'Ubicación principal por defecto de la sede.'
        if (detalle.value[index].AbsEntry) delete detalle.value[index].AbsEntry
      }
    }
    const remove = ({ data, index }) => {
      Swal.fire({
        icon: 'question',
        title: '¿Esta seguro/a?',
        text: 'Si elimina esta distribución, no se podrá recuperar, ¿Desea continuar?.',
        showConfirmButton: true,
        focusCancel: true,
        showCancelButton: true,
        confirmButtonText: 'Si, continuar',
        cancelButtonText: 'No, cancelar'
      }).then((resp) => {
        if (resp.isConfirmed) {
          if (data.id) {
            _RecepcionPedidosService.value.removeDistribucion(data.id).then((resp) => {
              if (resp.status === 200) {
                Swal.fire({
                  icon: 'success',
                  title: 'Éxito',
                  text: 'Distribución eliminada correctamente.',
                  showConfirmButton: false,
                  timer: 2500,
                  timerProgressBar: true
                }).then((resp) => {
                  emit('refrescarDistribucion', { data: encabezado.value })
                  const data = {
                    cantidad: detalle.value.reduce((acc, val) => acc + val.Quantity, 0),
                    line: encabezado.value
                  }
                  emit('refrescarCantidad', data)
                })
              }
            })
          } else {
            detalle.value.splice(index, 1)
            const data = {
              cantidad: detalle.value.reduce((acc, val) => acc + val.Quantity, 0),
              line: encabezado.value
            }
            emit('refrescarCantidad', data)
          }
        }
      })
    }
    const onCloseModal = () => {
      const data = {
        validate: detalle.value.some((x) => !x.WhsCode),
        line: encabezado.value
      }
      emit('validateBod', data)
    }
    const openDistribucionesModal = ({ dataMx, detallado, bod, distriBod, enca }) => {
      infoDoc.value = enca || {}
      detalleTemp.value = []
      detalle.value = []
      bod = bod.map((element) => {
        const find = detallado.find((x) => x.WhsCode === element.WhsCode)
        return find ? { ...element, SolicitudLinea: find.solicitudes, CantidadSolicitud: find.QuantitySol } : element
      })
      distribucionBodega.value = distriBod
      if (detalleTemp.value.some((x) => !x.id && !distriBod)) {
        detalle.value = detalleTemp.value
      } else {
        detalle.value = detallado
      }
      encabezado.value = dataMx
      if (detalleTemp.value.length === 0) detalleTemp.value = detallado
      bodegas.value = bod
      modalDistribucion.value = true
    }
    const initEditRows = (data) => {
      data.BodegaSelec = data.WhsCode
      data.NomBodegaSelec = data.WhsName
      editingRows.value = [data]
    }
    const onRowEditSave = ({ data }) => {
      if (onQuantity.value > encabezado.value.receivedQuantity) {
        Swal.fire({
          icon: 'warning',
          title: 'Advertencia',
          text: 'La sumatoria de la distribución supera la cantidad máxima disponible, por favor revisar.',
          showConfirmButton: true,
          confirmButtonText: 'Ok'
        })
        data.TempQuantity = data.Quantity
      } else {
        data.Quantity = data.TempQuantity
      }
    }
    const onChange = (event, { data, index }) => {
      const filterBodega = detalle.value.filter((x) => x.WhsCode === event.value)
      if (filterBodega.length >= 2 && event.value !== process.env.VUE_APP_WHSCODE_RECEPCION_PEDIDOS) {
        detalle.value[index].WhsCode = null
        Swal.fire({
          icon: 'warning',
          title: 'Advertencia',
          text: 'Esta bodega ya se encuentra asignada en esta distribución, por favor revisar.',
          showConfirmButton: false,
          timer: 2500,
          timerProgressBar: true
        })
      } else {
        const findNameBod = bodegas.value.find((l) => l.WhsCode === event.value)
        let findNameBodCearPed = []
        if (data.U_PHR_WarehouseCodeOri) findNameBodCearPed = bodegas.value.find((l) => l.WhsCode === data.U_PHR_WarehouseCodeOri)

        if (event.value === process.env.VUE_APP_WHSCODE_RECEPCION_PEDIDOS) {
          detalle.value[index].nombreUbicacion = process.env.VUE_APP_NOMBRE_UBICACION_PENDIENTESOV
          detalle.value[index].AbsEntry = process.env.VUE_APP_ABS_ENTRY_UBICACION_PENDIENTES
        } else {
          detalle.value[index].nombreUbicacion = 'Ubicación principal por defecto de la sede.'
          if (detalle.value[index].AbsEntry) delete detalle.value[index].AbsEntry
        }
        detalle.value[index].WhsName = findNameBod.WhsName
        detalle.value[index].NombreBodegaOri = findNameBodCearPed.WhsName
        detalle.value[index].CodBodegaOri = findNameBodCearPed.WhsCode
        detalle.value[index].solicitudes = findNameBod.SolicitudLinea
        detalle.value[index].QuantitySol = findNameBod.CantidadSolicitud
        detalle.value[index].distribucionNueva = !findNameBod.SolicitudLinea
      }
    }
    const addDistribucion = () => {
      detalle.value.push({
        WhsCode: null,
        Quantity: encabezado.value.receivedQuantity - onQuantity.value,
        TempQuantity: encabezado.value.receivedQuantity - onQuantity.value,
        WhsName: null,
        nombreUbicacion: 'Ubicación principal por defecto de la sede.',
        solicitudes: [],
        distribucionNueva: true
      })
      const data = {
        cantidad: detalle.value.reduce((acc, val) => acc + val.Quantity, 0),
        line: encabezado.value
      }
      emit('refrescarCantidad', data)
    }
    return {
      modalDistribucion,
      openDistribucionesModal,
      addDistribucion,
      bodegas,
      onChange,
      editingRows,
      onRowEditSave,
      onQuantity,
      onSubmit,
      encabezado,
      detalle,
      remove,
      onCloseModal,
      distribucionBodega,
      onRowEditCancel,
      initEditRows,
      printPdf,
      infoDoc
    }
  }
}
</script>

<style scoped>
::v-deep(.pi) {
  font-size: 0.8rem;
}

::v-deep(.p-column-header-content) {
  justify-content: center;
}

::v-deep(.p-inputnumber-input) {
  width: 3rem;
}
</style>
